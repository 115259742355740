import React from "react";
import classnames from "classnames/bind";
import { useNavigate } from "react-router-dom";
import style from "./index.module.css";
import logoWhiteLanding from "../../assets/logo_white_landing.svg";
import logoLanding from "../../assets/logo_landing.svg"
import ps1 from "../../assets/ps1.png";
import ps2 from "../../assets/ps2.png";
import ps3 from "../../assets/ps3.png";
import AOS from "aos";
import "aos/dist/aos.css";

import { Facebook, Instagram, Linkedin, Twitter, List, X , ChevronDoubleDown} from "react-bootstrap-icons";

const cx = classnames.bind(style);

const LandingPage: React.FC = () => {
  const [showList, setShowList] = React.useState(false);
  const navigate = useNavigate();
  const  aboutRef = React.useRef<HTMLDivElement>(null);
  const  exRef = React.useRef<HTMLDivElement>(null);
  const  crRef = React.useRef<HTMLDivElement>(null);
  const  comingRef = React.useRef<HTMLDivElement>(null);



  React.useEffect(() => {
    AOS.init({duration: 1000});
    AOS.refresh();
  }, []);
  return (
    <div className={cx("landing-wrapper")}>
      <div className={cx("wrapper")}>
        <header>
          <div className={cx("hd-top")}>
            <img src={logoWhiteLanding} alt=""/>
            <i className={cx("fa-solid", "fa-bars")} onClick={()=> setShowList( prev => !prev )}>{showList ? <X size={50}/>:<List size={50}/>}</i>
            <ul className={cx(showList? 'show':'hide')}>
              <li onClick={()=>aboutRef.current?.scrollIntoView({behavior:'smooth'})}><p>About Us</p></li>
              <li onClick={()=>exRef.current?.scrollIntoView({behavior:'smooth'})}><p>Music Creation</p></li>
              <li onClick={()=>crRef.current?.scrollIntoView({behavior:'smooth'})}><p>Experience</p></li>
              <li onClick={()=>comingRef.current?.scrollIntoView({behavior:'smooth'})}><p>Coming soon</p></li>
              <li onClick={()=>navigate(`/wavv`)}><p>Wavv.app</p></li>
            </ul>
          </div>
          <div className={cx("hd-content")}>
            <div className={cx("hd-content_title")}>
              <h1>Wavv</h1>
              <h2>MusicGPT v2</h2>
            </div>
            <a href="https://musicgpt.wavv.app/" className={cx("hd-enter")}>Enter</a>
            <i className={cx("fa-solid","fa-angles-down")}><ChevronDoubleDown className="ml-4" size={40}/></i>
          </div>
        </header>
        <div className={cx("about")} ref={aboutRef}>
          <div className={cx("about-content")}>
            <h3>About Us</h3>
            <img src={logoLanding} alt=""/>
            <p>
              <span>Welcome to Wavv, where artificial intelligence and music composition converge to redefine creativity. You are invited to try our platform, <b>MusicGPT v2</b>, which represents a significant leap in the field of generative AI, offering a new dimension to music creation. At Wavv, we're not just about developing software; we're about crafting a new language of music through the lens of cutting-edge technology. Wavv’s focus is on empowering artists and producers, providing them with tools that enhance creativity and unlock new possibilities in music production.</span>
              <span><b>MusicGPT v2</b>, the AI-driven composition tool, is designed for both accessibility and depth. It's an innovation that transforms the way music is composed, making the process more intuitive, yet deeply sophisticated. With Wavv, you're at the forefront of a musical evolution, where technology doesn't just assist but inspires.</span>
              <span>Join us as we explore the future of music, where every melody is a discovery and every rhythm opens new horizons.</span>
            </p>
          </div>
        </div>
        <div className={cx("main-block")} ref={crRef}>
          <div className={cx("main-block-content")}>
            <img src={ps1} alt="" data-aos="fade-up"/>
            <div className={cx("main-block-content_text")} data-aos="fade-left">
              <h3><span>Music Creation, Within Reach:</span> Redefining Music Composition</h3>
              <p>Experience Wavv's MusicGPT v2, making music creation simple, intuitive, and profound.</p>
            </div>
          </div>
        </div>

        {/* Block 2  */}
        <div className={cx("main-block","block-gray")} ref={exRef}>
          <div className={cx("main-block-content")}>
              <img src={ps2} alt="" data-aos="fade-up"/>
            <div className={cx("main-block-content_text")} data-aos="fade-right">
              <h3><span>Experience the Magic of Musica:</span> The World's First Large Music Language Model</h3>
              <p>Dive into the enchanting world of Musica, where every note weaves a new realm of possibilities.</p>
            </div>
          </div>
        </div>
        {/* Block 3  */}
        <div className={cx("main-block")} ref={comingRef}>
          <div className={cx("main-block-content")}>
            <img src={ps3} alt="" data-aos="fade-up"/>
            <div className={cx("main-block-content_text")} data-aos="fade-left">
              <h2><b>Coming soon</b></h2>
              <h3><span>Enjoy Collaborative Creation:</span> Unite with Fellow Music Creators</h3>
              <p>Embrace the joy of co-creating music, blending your creativity with others for unparalleled harmony.</p>
            </div>
          </div>
        </div>

          {/* Footer */}
        <footer>
          <ul>
            <li><a href="https://twitter.com/thewavvapp" target="_blank"><i className={cx("fa-brands","fa-twitter")}><Twitter/></i></a></li>
            <li><a href="https://www.linkedin.com/company/wavv%E3%81%AE/" target="_blank"><i className={cx("fa-brands","fa-linkedin-in")}><Linkedin /></i></a></li>
            <li><a href="https://www.facebook.com/wavvmusic" target="_blank"><i className={cx("fa-brands","fa-facebook-f")}><Facebook /></i></a></li>
            <li><a href="https://www.instagram.com/thewavvapp?igsh=OGQ5ZDc2ODk2ZA==" target="_blank"><i className={cx("fa-brands","fa-instagram")}><Instagram /></i></a></li>
          </ul>
          <p>Copyright: Wavv, All Rights Reserved.</p>
        </footer>
      </div>
    </div>
  );
};

export default LandingPage;
